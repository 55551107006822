
<div class="wnupload-container">
    <div
        class="dropzone"
        :class="{ 'drag-over': isDragging }"
        @dragenter.prevent="onDragEnter"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop"
        @click="triggerFileInput">
        
        <template v-if="viewState === VIEW_STATE.idle && files.length > 0">
            <button class="clear-button" 
                @click.stop="clearFiles">
                {{ $_locale('clear') }}
            </button>

            <button
                @click.stop="uploadFiles">
                {{ $_locale(`upload file${ files.length > 1 ? 's' : '' }`) }}
            </button>
        </template>

        <p v-if="files.length === 0">
            {{ $_locale('drop files here or click this box to select files') }}
        </p>
        
        <WnFileList 
            v-if="files.length > 0"
            :files="files" />
    </div>
    <input
        type="file"
        ref="fileInput"
        multiple
        :accept="acceptFormats.join(',')"
        @change="onFilesSelected"
        style="display: none"
    />
    
</div>
