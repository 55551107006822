<template>
<div :class="{
    'input-group': hasInputGroup,
}">
    <input type="text" 
        ref="dateInput"
        class="form-control"
        :placeholder="placeholder" />
    <span class="input-group-btn" v-if="hasInputGroup">
        <button class="btn btn-default" @click="resetDatePicker">
            <i class="glyphicon glyphicon-remove"></i>
        </button>
    </span>
</div>
</template>

<script>
export default {
    props: {
        settings: {
            type: Object,
            required: true,
        },
        enableReset: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        hasInputGroup() {
            // multiple things in the future might affect this
            return this.enableReset;
        },
    },
    watch: {
        settings: {
            deep: true,
            immediate: true,
            handler(val) {
                let startDate = null
                let endDate = null
                
                if (val?.startDate) {
                    startDate = moment(val?.startDate)
                }

                if (val?.endDate) {
                    endDate = moment(val?.endDate)
                }
                // meets minimum requirement
                if (startDate) {
                    this.setDate(startDate, endDate)
                }
            },
        }
    },
    methods: {
        getDate() {
            const dateInput = this.$refs['dateInput'];
            const { startDate, endDate } = $(dateInput).data('daterangepicker')
            
            return { startDate, endDate }
        },
        setDate(startDate, endDate = null) {
            const dateInput = this.$refs['dateInput']
            const picker = $(dateInput).data('daterangepicker')
            
            if (!picker) return;

            picker.setStartDate(startDate)
            picker.setEndDate(endDate || startDate)
        },
        resetDatePicker() {
            const dateInput = this.$refs['dateInput']

            $(dateInput).val('')

            this.$emit('change', null)
        },
        init() {
            const dateInput = this.$refs['dateInput']
            const { settings } = this
            const defaultSettings = {
                locale: { 
                    format: 'DD.MM.YYYY',
                    daysOfWeek: _localeDates.dayNamesMin,
                    monthNames: _localeDates.monthNamesShort,
                    firstDay: 1,
                },
            }
            let { startDate, endDate } = settings

            if (typeof startDate === 'string' && startDate.length > 0) {
                startDate = moment(startDate)
            }

            if (typeof endDate === 'string' && endDate.length > 0) {
                endDate = moment(endDate)
            }
            
            $(dateInput).daterangepicker(
                {
                    ...defaultSettings,
                    ...settings,
                    startDate: startDate || moment(),
                    endDate: endDate, // no default
                }, 
                (startDate, endDate) => { // This is NOT called when user selects the date of today, if startDate is moment() without a parameter, i.e. if <wn-date-picker> was initiated with a falsy startDate.
                    this.$emit('change', { startDate, endDate })
                }
            ).on('apply.daterangepicker', (event, picker) => { // This is called even if daterangepicker thinks the selected date did not change.
                this.$emit('change', {startDate: picker.startDate, endDate: picker.endDate}); // Emit the same event as the normal change would do.
            })

            if (!startDate) {
                this.$nextTick(() => this.resetDatePicker())
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>